import React, {useEffect, useState} from 'react';
import './UserPage.css';
import {Link, useNavigate, useParams} from "react-router-dom";
import userLogo from "../../../assets/user.svg";
import letter from "../../../assets/letter.svg";
import {useJwt} from "../../../context/JwtContext";
import {isAdmin, isPrivilegeUser, UserStatus} from "../../../utils/userUtil";
import * as client from "../../../utils/client";
import ProposedProblemsList from "../../ProposedProblemsList/ProposedProblemsList";
import {useTelegram} from "../../../hooks/useTelegram";

const UserPage = ({users, isDesktop, setDeferredProblems, userProblemsData}) => {
    const {user} = useJwt();
    const {telegram} = useTelegram();
    const {userId} = useParams();
    const navigate = useNavigate();
    const [problems, setProblems] = useState([]);
    const [userById, setUserById] = useState(user);
    const [userProblems, setUserProblems] = useState([]);
    const [showProposedProblemsList, setShowProposedProblemsList] = useState(false);

    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (window.history.length > 1) {
            telegram.BackButton.show();
            telegram.BackButton.onClick(goBack);
        } else {
            telegram.BackButton.hide();
        }
        return () => {
            telegram.BackButton.offClick(goBack);
        };
    }, [telegram, navigate]);

    useEffect(() => {
        if (users.find(u => u.id === Number(userId))) {
            setUserById(users.find(u => u.id === Number(userId)));
        }
    }, []);

    useEffect(() => {
        if (user.id === Number(userId) && isPrivilegeUser(user)) {
            client.getProposedProblems()
                .then((problemsJson) => {
                    const mapped = problemsJson.map((problem) => ({
                        id: problem[0],
                        author_id: problem[1],
                        theme: problem[2],
                        name: problem[3],
                    }));
                    setProblems(mapped);
                    setUserProblems(mapped);
                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (user.id === Number(userId) && user.role === "USER") {
            setUserProblems(userProblemsData);
            setProblems(userProblemsData);
        }
    }, []);

    if (user.id !== Number(userId) && !isAdmin(user)) {
        return <div color={'black'}>
            Недостаточно прав
        </div>;
    }

    return (
        <div className={'user-page-container ' + (isDesktop ? 'rowed' : '')}>
            <div className='user-page-main'>
                <img style={{
                    width: "50%",
                    margin: "0 auto",
                    paddingTop: "1rem"
                }} src={userLogo} alt={'user-logo'}/>
                <ul>
                    <li>
                        {userById.firstName} {userById.lastName}
                    </li>
                    <li>
                        {userById.email}
                    </li>
                    <li>
                        {userById.username}
                    </li>
                </ul>
            </div>
            <div className='user-page-main'>
                <ul>
                    <li>
                        {userById.role}
                    </li>
                    <li>
                        {UserStatus[userById.status]}
                    </li>
                    <li>
                        {userById.placeOfWorkOrStudy} / {userById.classOrCourse}
                    </li>
                    <li>
                        {userById.countryOfResidence}
                    </li>
                    <li>
                        {userById.city}
                    </li>
                    <li>
                        Рейтинг: {userById.rating}
                    </li>
                    <h1 className='user-problems-header'>{user.role === "USER" ? "Мои задачи" : "Предложенные задачи"}</h1>
                    {userProblems.slice(0, 4).map((problem) => (
                        <li key={problem.id}>
                            <Link to={`/problem/${problem.id}`} className='problem-name'>
                                {problem.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            {user.id === Number(userId) &&
                <div className='proposed-problems-container'>
                    <div className="proposed-problems-icon-wrapper">
                        <img src={letter} alt={'proposed-problems'}
                             onClick={() => setShowProposedProblemsList(!showProposedProblemsList)}/>
                        <span className="proposed-problems-count">{problems.length}</span>
                    </div>
                </div>
            }

            {showProposedProblemsList &&
                <ProposedProblemsList setDeferredProblems={setDeferredProblems} setProblems={(p) => {
                    setProblems(p);
                    setUserProblems(p)
                }}
                                      problems={problems} onClose={() => setShowProposedProblemsList(false)}/>}
        </div>
    );
};


export default UserPage;
