import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {JwtProvider, useJwt} from "./context/JwtContext";
import NavBar from "./components/NavBar/NavBar";
import HomePage from "./components/page/HomePage/HomePage";
import ReviewsPage from "./components/page/ReviewsPage/ReviewsPage";
import ProblemPage from "./components/page/ProblemPage/ProblemPage";
import AdminPage from "./components/page/AdminPage/AdminPage";
import RegisterPage from "./components/page/RegisterPage/RegisterPage";
import UsersPage from "./components/page/UsersPage/UsersPage";
import ReviewPage from "./components/page/ReviewPage/ReviewPage";
import ProblemsPage from "./components/page/ProblemsPage/ProblemsPage";
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner";
import {isAdmin, isPrivilegeUser} from "./utils/userUtil";
import {useEffect, useState} from "react";
import {useTelegram} from "./hooks/useTelegram";
import Header from "./components/Header/Header";
import UserPage from "./components/page/UserPage/UserPage";

function AppContent() {
    const {
        jwtToken, user, loading, authError, userProblemsData, setUserProblemsData, problems,
        users, setUsers,
        userReviewList, setUserReviewList,
        deferredProblems, setDeferredProblems
    } = useJwt();
    const [showConfirmation, setShowConfirmation] = useState(false);

    const reviewers = users.filter(u => isPrivilegeUser(u));
    const {telegram} = useTelegram();
    const isDesktop = telegram.platform === "tdesktop";
    const isAndroid = telegram.platform === "android";

    if (loading) {
        return <LoadingSpinner/>;
    }

    if (authError) {
        return (
            <div className="auth-error-container">
                <h2>Ошибка авторизации</h2>
                <p>{authError}</p>
                <button onClick={() => window.location.reload()}>Повторить</button>
            </div>
        );
    }

    return (<Router>
            {jwtToken && user
                ? (<>
                    <Header isAndroid={isAndroid} showConfirmation={showConfirmation}/>
                    {isPrivilegeUser(user) && !isAndroid && <NavBar showConfirmation={showConfirmation}/>}
                    <div className='page-container'>
                        <Routes>
                            <Route path="/" element={<HomePage
                                showConfirmation={showConfirmation} setShowConfirmation={setShowConfirmation}
                                isMobile={!isDesktop} user={user} userProblems={userProblemsData}
                                setUserProblems={setUserProblemsData}/>}/>
                            <Route path="/problem/:problemId" element={<ProblemPage/>}/>
                            <Route path="/user/:userId"
                                   element={<UserPage setDeferredProblems={setDeferredProblems} users={users}
                                                      userProblemsData={userProblemsData}
                                                      isDesktop={isDesktop}/>}/>

                            {isPrivilegeUser(user) && (
                                <>
                                    <Route path="/reviews" element={<ReviewsPage
                                        reviewers={reviewers}
                                        deferredProblems={deferredProblems}
                                        userReviewList={userReviewList}
                                        setUserReviewList={setUserReviewList}
                                        setDeferredProblems={setDeferredProblems}/>}/>
                                    <Route path="/review/:reviewId" element={<ReviewPage/>}/>
                                </>
                            )}

                            {isAdmin(user) && (
                                <>
                                    <Route path="/users" element={<UsersPage users={users} problems={problems}
                                                                             setUsers={setUsers}/>}/>
                                    <Route path="/problems" element={<ProblemsPage problems={problems}/>}/>
                                    <Route path="/admin" element={<AdminPage users={users} setUsers={setUsers}/>}/>
                                </>
                            )}
                        </Routes>
                    </div>
                </>)
                : (<RegisterPage/>)}
        </Router>
    );
}

function App() {
    const {telegram} = useTelegram();

    useEffect(() => {
        telegram.ready();
        telegram.expand();
        telegram.disableVerticalSwipes();
    }, [telegram]);

    return (
        <JwtProvider>
            <AppContent/>
        </JwtProvider>
    );
}

export default App;
