import React, {useEffect, useState} from 'react';
import './HomePage.css';
import emptyProblemsLogo from "../../../assets/emptyProblemsLogo.svg";
import AddProblemForm from '../../form/AddProblemForm/AddProblemForm.js';
import {useTelegram} from "../../../hooks/useTelegram";
import ProblemList from "../../ProblemList/ProblemList";

const HomePage = ({showConfirmation, setShowConfirmation, isMobile, user, userProblems, setUserProblems}) => {
    const {telegram} = useTelegram();
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        telegram.BackButton.hide();
    }, []);

    return (
        <>
            <div className={`${showConfirmation ? 'blur' : ''}`}>
                <div className='home-page-header'>
                    <h2>Задачи ({userProblems.length})</h2>
                    <button className='add-problem-button' onClick={() => setShowForm(!showForm)}>
                        Добавить задачу
                    </button>
                </div>
                {userProblems && userProblems.length > 0
                    ? <ProblemList problems={userProblems}/>
                    : <div className='empty-problem-list'>
                        <p>Пока добавленных задач нет...</p>
                        {!isMobile && <img src={emptyProblemsLogo}/>}
                    </div>
                }
            </div>
            {showForm
                && <AddProblemForm
                    showConfirmation={showConfirmation}
                    setShowConfirmation={setShowConfirmation}
                    isMobile={isMobile}
                    user={user}
                    onClose={() => setShowForm(false)}
                    onProblemAdded={(newProblem) => setUserProblems(previousProblems => [...previousProblems, newProblem])}/>
            }
        </>);
};

export default HomePage;
