import React, {useEffect, useState} from 'react';
import './UsersPage.css';
import {useTelegram} from "../../../hooks/useTelegram";
import userLogo from "../../../assets/user.svg";
import {Link} from "react-router-dom";

const UsersPage = ({users, problems, setUsers}) => {
    const {telegram} = useTelegram();
    const [searchQuery, setSearchQuery] = useState('');
    const [sortOption, setSortOption] = useState('rating-desc');

    useEffect(() => {
        telegram.BackButton.hide();
    }, [telegram]);

    useEffect(() => {
        const usersWithProblems = users.map(user => {
            const userProblems = problems.filter(problem => problem.author.tgId === user.tgId);
            return {...user, problems: userProblems};
        });

        setUsers(usersWithProblems);
    }, [users, problems]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSort = (e) => {
        setSortOption(e.target.value);
    };

    const filteredUsers = users
        .filter(user => {
            const fullName = `${user.lastName}, ${user.firstName}`.toLowerCase();
            return fullName.includes(searchQuery.toLowerCase());
        })
        .sort((a, b) => {
            switch (sortOption) {
                case 'rating-asc':
                    return a.rating - b.rating;
                case 'rating-desc':
                    return b.rating - a.rating;
                case 'problems-asc':
                    return a.problems.length - b.problems.length;
                default:
                    return 0;
            }
        });

    return (
        <div className='authors-list'>
            <div className='authors-filters'>
                <select
                    className='users-sort-select'
                    id="sort-select"
                    value={sortOption}
                    onChange={handleSort}
                >
                    <option value="rating-asc">Рейтинг автора (asc)</option>
                    <option value="rating-desc">Рейтинг автора (desc)</option>
                    <option value="problems-asc">Количество задач (asc)</option>
                </select>
            </div>
            <div className='authors-search-bar'>
                <input
                    type="text"
                    placeholder="Поиск по фамилии, имени..."
                    value={searchQuery}
                    onChange={handleSearch}
                    className='authors-search-input'
                />
            </div>
            {filteredUsers.length > 0
                ?
                <table className='authors-table'>
                    <thead>
                    <tr style={{borderBottom: "2px solid black"}}>
                        <td>Имя Фамилия</td>
                        <td>Рейтинг</td>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredUsers.map((user => (
                        <tr>
                            <td style={{color: "#2B53A0"}}>
                                <Link to={`/user/${user.id}`} className='problem-name'>
                                    {user.firstName} {user.lastName}
                                </Link>
                            </td>
                            <td>{user.rating}</td>
                        </tr>
                    )))}
                    </tbody>
                </table>
                : (<p className='no-users-message'>No users found.</p>)}
        </div>
    );
};

export default UsersPage;
