import React from 'react';
import './ProblemReviewsModal.css';

const ProblemReviewsModal = ({reviews, onClose}) => {
    return (
        <div className='problem-review-modal'>
            <div className='problem-review-modal-content'>
                <h3>Ревью задачи</h3>
                {reviews.length > 0
                    ? <ol style={{listStyleType: "upper-roman", color: "black"}}>
                        {reviews
                            .filter(review => !review.deferred)
                            .map((review, index) => (
                                <li key={index} className="review-card">
                                    <p>Автор ревью: {review.reviewAuthorCredentials}</p>
                                    <p>
                                        Баян:{" "}
                                        {review.wellKnown ? (
                                            <>
                                                Да, <a href={review.sourceUrl}>{review.sourceUrl}</a>
                                            </>
                                        ) : (
                                            "Нет"
                                        )}
                                    </p>
                                    <p>{review.text}</p>
                                </li>
                            ))}
                    </ol>
                    :
                    <p className="no-reviews">Нет ревью для этой задачи.</p>
                }

                <button className='problem-modal-close-button' onClick={onClose}>
                    Закрыть
                </button>
            </div>
        </div>

    );
};

export default ProblemReviewsModal;
