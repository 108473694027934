import React, {useEffect, useRef, useState} from 'react';
import './Header.css';
import userLogo from "../../assets/user.svg";
import {useJwt} from "../../context/JwtContext";
import {Link, NavLink} from "react-router-dom";
import {useHapticFeedback} from "@vkruglikov/react-telegram-web-app";
import {NavBarRouter} from "../NavBar/NavBar";

const Header = ({isAndroid, showConfirmation}) => {
    const {user} = useJwt();
    const [impactOccurred] = useHapticFeedback();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const menuRef = useRef(null);


    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <div className={`header ${showConfirmation ? 'blur' : ''}`}>
            {isAndroid ? (
                <div>
                    <p
                        onClick={() => {
                            impactOccurred('light');
                            setIsMenuOpen((prev) => !prev);
                        }}
                        className="header-title"
                    >
                        ДАБРОМАТ
                    </p>
                    {isMenuOpen && (
                        <div ref={menuRef} className="dropdown-menu">
                            <ul>
                                {NavBarRouter(user).map((item, index) => (
                                    <li key={index} className={item.cName}>
                                        <NavLink to={item.path} onClick={() => setIsMenuOpen(false)}>
                                            {item.icon}
                                            <span>{item.title}</span>
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            ) : (
                <p>
                    <Link
                        style={{
                            color: "white",
                            textDecoration: "none"
                        }}
                        to={'/'}
                        className="problem-name"
                        onClick={() => impactOccurred('light')}
                    >
                        ДАБРОМАТ
                    </Link>
                </p>
            )}
            <Link to={`/user/${user.id}`} className="problem-name" onClick={() => impactOccurred('light')}>
                <img src={userLogo} alt={'logo'}/>
            </Link>
        </div>
    );
};

export default Header;
