import React, {useState} from 'react';
import './ReviewersList.css';
import * as client from "../../utils/client";

const ReviewersList = ({reviewers, onClose, problemId}) => {
    const [selectedReviewers, setSelectedReviewers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const handleCheckboxChange = (id) => {
        setSelectedReviewers(prev => {
            if (prev.includes(id)) {
                return prev.filter(reviewerId => reviewerId !== id);
            } else {
                return [...prev, id];
            }
        });
    };

    const handleSubmit = () => {
        client.sentProblemToReview(selectedReviewers, problemId)
            .then((response) => {
                alert(response);
                onClose();
            })
            .catch(error => {
                console.error("Error sending problem:", error);
            })
    };

    const filteredReviewers = reviewers.filter((reviewer) =>
        reviewer.username.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className='reviewers-modal-overlay'>
            <div className='reviewers-modal'>
                <div className='reviewers-modal-header'>
                    <h3>Список ревьюверов</h3>
                    <button className="close-modal-button" onClick={onClose}>×</button>
                </div>
                <div className='reviewers-input-search'>
                    <input
                        type='text'
                        placeholder='Поиск по нику'
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="search-input"
                    />
                </div>
                <div className='reviewers-modal-body'>
                    <table>
                        <tbody>
                        {filteredReviewers.map((reviewer) => (
                            <tr key={reviewer.id}>
                                <td>{reviewer.username}</td>
                                <td>
                                    <input
                                        type='checkbox'
                                        checked={selectedReviewers.includes(reviewer.id)}
                                        onChange={() => handleCheckboxChange(reviewer.id)}
                                    />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <button className="add-problem-submit-button" style={{margin: "1rem auto"}}
                        onClick={handleSubmit}>Отправить
                </button>
            </div>
        </div>
    );
};

export default ReviewersList;
