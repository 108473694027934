import React, {useState} from 'react';
import './AddReviewForm.css';
import LatexRenderer from './../../LatexRenderer/LatexRenderer';

const beautyComments = {
    1: "Задача не красива и не имеет ценности",
    2: "Задача скорее не нравится",
    3: "В целом норм задача есть серьезные противопоказания",
    4: "Хорошая задача с незначительными противопоказаниями",
    5: "Хорошая задача, нравится",
    6: "Шедевр, Не ставьте задачам сложности меньше 5",
};

const AddReviewForm = ({onSubmit, onClose, deferProblem}) => {
    const [generalError, setGeneralError] = useState(null);
    const [text, setText] = useState('');
    const [beauty, setBeauty] = useState(3);
    const [complexity, setComplexity] = useState(3);
    const [wellKnown, setWellKnown] = useState(false);
    const [sourceUrl, setSourceUrl] = useState('');
    const [tag, setTag] = useState('');
    const [errors, setErrors] = useState({});
    const [latexError, setLatexError] = useState(null);

    const validate = () => {
        const newErrors = {};

        if (beauty < 1 || beauty > 6) {
            newErrors.beauty = 'Красота должна быть от 1 до 6.';
        }
        if (complexity < 1 || complexity > 6) {
            newErrors.complexity = 'Сложность должна быть от 1 до 6.';
        }
        if (wellKnown && !sourceUrl.trim()) {
            newErrors.sourceUrl = 'Источник обязателен для известных задач.';
        }
        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        setGeneralError(null);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        onSubmit({
            text,
            beauty,
            complexity,
            wellKnown,
            sourceUrl: wellKnown ? sourceUrl.trim() : null,
            tag
        }, setGeneralError);
    };

    return (
        <div className='add-review-form'>
            <div className='problem-review-modal-container'>
                <textarea
                    id='text'
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    rows={8}
                    style={{width: '100%'}}
                    placeholder='Коментарий ревьювера'
                />
                {errors.text && <span className='error'>{errors.text}</span>}
                {text && <LatexRenderer latexCode={text} onError={setLatexError}/>}
                {latexError && <span className="error">Ошибка LaTeX: {latexError}</span>}
            </div>

            <div className='problem-review-modal-container'>
                <input
                    id='tag'
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                    maxLength={100}
                    width={"100%"}
                    placeholder='Введите тэг'
                />
                {errors.tag && <span className='error'>{errors.tag}</span>}
            </div>

            <div className='review-modal-select-container'>
                <div className='problem-review-modal-container'>
                    <select
                        id='beauty'
                        value={beauty}
                        onChange={(e) => setBeauty(parseInt(e.target.value))}
                        required
                    >
                        <option value={3}>Красота</option>
                        {[1, 2, 3, 4, 5, 6].map((value) => (
                            <option key={value} value={value}>{value}</option>
                        ))}
                    </select>
                    {beautyComments[beauty] && (
                        <p className='beauty-comment'>{beautyComments[beauty]}</p>
                    )}
                    {errors.beauty && <span className='error'>{errors.beauty}</span>}
                </div>

                <div className='problem-review-modal-container'>
                    <select
                        id='complexity'
                        value={complexity}
                        onChange={(e) => setComplexity(parseInt(e.target.value))}
                        required
                    >
                        <option value={3}>Сложность</option>
                        {[1, 2, 3, 4, 5, 6].map((value) => (
                            <option key={value} value={value}>{value}</option>
                        ))}
                    </select>
                    {errors.complexity && <span className='error'>{errors.complexity}</span>}
                </div>
            </div>

            <div className='problem-review-modal-container'
                 style={{
                     display: "flex",
                     flexDirection: "row"
                 }}>
                <input
                    style={{flex: "0"}}
                    type='checkbox'
                    id='wellKnown'
                    checked={wellKnown}
                    onChange={(e) => setWellKnown(e.target.checked)}
                />
                <label style={{marginLeft: "0.2rem"}} htmlFor='wellKnown'>Баян</label>
            </div>
            <div className='problem-review-modal-container'>
                {wellKnown && (
                    <div>
                        <label htmlFor='sourceUrl'>Источник</label>
                        <input
                            type='text'
                            id='sourceUrl'
                            value={sourceUrl}
                            onChange={(e) => setSourceUrl(e.target.value)}
                            placeholder='Введите ссылку на источник'
                            required={wellKnown}
                        />
                        {errors.sourceUrl && <span className='error'>{errors.sourceUrl}</span>}
                    </div>
                )}
            </div>
            {generalError && <p className="error general-error">{generalError}</p>}
            <div className='problem-review-buttons'>
                {/*#03A696*/}
                <button className='problem-review-buttons-defer' onClick={deferProblem}>Отложить</button>

                {/*#2B53A0*/}
                <button type='submit' className='problem-review-buttons-submit' onClick={handleSubmit}>Оценить</button>

                {/*#FF5050*/}
                <button className='problem-review-buttons-close' onClick={onClose}>Закрыть</button>
            </div>
        </div>
    );
};

export default AddReviewForm;
