import React, {useEffect, useState} from 'react';
import './ProblemsPage.css';
import {Link} from "react-router-dom";
import {ProblemTheme, ProblemType} from "../../../utils/problemUtil";
import {useTelegram} from "../../../hooks/useTelegram";
import {useJwt} from "../../../context/JwtContext";
import {FaEye} from "react-icons/fa";
import ProblemInfoModal from "../../ProblemInfoModal/ProblemInfoModal";
import ProblemReviewsModal from "../../ProblemReviewsModal/ProblemReviewsModal";

const ProblemsPage = ({problems}) => {
    const {telegram} = useTelegram();
    const [minRating, setMinRating] = useState('');
    const [maxRating, setMaxRating] = useState('');
    const [reviews, setReviews] = useState([]);
    const [isReviewModalOpen, setReviewModalOpen] = useState(false);
    const [sortConfig, setSortConfig] = useState({key: 'complexity', direction: 'descending'});
    const [filters, setFilters] = useState({theme: 'All', type: 'All'});
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedProblem, setSelectedProblem] = useState(null);

    const themes = Object.entries(ProblemTheme).map(([key, value]) => ({key, value}));
    const types = Object.entries(ProblemType).map(([key, value]) => ({key, value}));

    useEffect(() => {
        telegram.BackButton.hide();
    }, []);


    const sortedProblems = React.useMemo(() => {
        let sortableProblems = [...problems];

        if (filters.theme !== 'All') {
            sortableProblems = sortableProblems.filter(problem => problem.theme === filters.theme);
        }
        if (filters.type !== 'All') {
            sortableProblems = sortableProblems.filter(problem => problem.type === filters.type);
        }

        if (searchQuery) {
            sortableProblems = sortableProblems.filter(problem =>
                problem.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        if (minRating !== '') {
            sortableProblems = sortableProblems.filter(problem => problem.averageRating >= minRating);
        }

        if (maxRating !== '') {
            sortableProblems = sortableProblems.filter(problem => problem.averageRating <= maxRating);
        }

        if (sortConfig !== null) {
            sortableProblems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        return sortableProblems;
    }, [problems, sortConfig, filters, searchQuery, minRating, maxRating]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({key, direction});
    };

    const handleFilterChange = (e) => {
        const {name, value} = e.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: value
        }));
    };

    const fetchReviews = (problemId) => {
        setReviews(problems.filter((problem) => problem.id === problemId)[0].reviews);
        setReviewModalOpen(true);
    };

    return (
        <div className='problems-page'>
            <div className='filters-input-container'>
                <div className='problem-search-filters'>
                    <select name="theme" value={filters.theme} onChange={handleFilterChange}>
                        <option value="All">Тема</option>
                        {themes.map(({key, value}) => (
                            <option key={key} value={key}>{value}</option>
                        ))}
                    </select>

                    <select name="type" value={filters.type} onChange={handleFilterChange}>
                        <option value="All">Тип</option>
                        {types.map(({key, value}) => (
                            <option key={key} value={key}>{value}</option>
                        ))}
                    </select>

                    <div className='complexity-filter-container'>
                        <p style={{marginTop: "0.3rem", paddingLeft: "1rem"}}>Рейтинг</p>
                        <input
                            type="number"
                            value={minRating}
                            onChange={(e) => setMinRating(e.target.value)}
                            placeholder="от"
                        />

                        <input
                            type="number"
                            value={maxRating}
                            onChange={(e) => setMaxRating(e.target.value)}
                            placeholder="до"
                        />
                    </div>
                </div>
                <div className='problem-search-input'>
                    <input
                        type="text"
                        placeholder="Поиск по названию задачи..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
            </div>

            <table className='problems-table'>
                <thead>
                <tr>
                    <th>Имя Задачи</th>
                    <th onClick={() => requestSort('averageRating')} className='sortable'>
                        Рейтинг
                        {sortConfig.key === 'averageRating' ? (
                            sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'
                        ) : null}
                    </th>
                    <th onClick={() => requestSort('wellKnownCount')} className='sortable'>
                        Баяны
                        {sortConfig.key === 'wellKnownCount' ? (
                            sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'
                        ) : null}
                    </th>
                    <th>Тема</th>
                    <th>Тип</th>
                    <th>Решение</th>
                    <th>Автор</th>
                </tr>
                </thead>
                <tbody>
                {sortedProblems.length > 0 ? (
                    sortedProblems.map(problem => (
                        <tr key={problem.id}>
                            <td>
                                <Link to={`/problem/${problem.id}`} className='problem-link'>
                                    {problem.name}
                                </Link>
                            </td>
                            <td>{problem.averageRating}</td>
                            <td style={{textDecoration: 'underline'}} onClick={() => fetchReviews(problem.id)}>
                                {problem.wellKnownCount}
                            </td>
                            <td>{ProblemTheme[problem.theme] || problem.theme}</td>
                            <td>{ProblemType[problem.type] || problem.type}</td>
                            <td style={{textDecoration: 'underline'}} onClick={() => setSelectedProblem(problem)}>
                                <FaEye/>
                            </td>
                            <td>{problem.author.username}</td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="7">Нет задач, соответствующих фильтрам.</td>
                    </tr>
                )}
                </tbody>
            </table>

            {selectedProblem && (
                <ProblemInfoModal problem={selectedProblem} onClose={() => setSelectedProblem(null)}/>)}

            {isReviewModalOpen && (
                <ProblemReviewsModal reviews={reviews} onClose={() => setReviewModalOpen(false)}/>
            )}
        </div>
    );
};

export default ProblemsPage;
