import React from 'react';
import './ProblemInfoModal.css';
import LatexRenderer from "../LatexRenderer/LatexRenderer";
import * as constants from "../../constants/constants";
import {FaFileDownload} from "react-icons/fa";
import {useTelegram} from "../../hooks/useTelegram";
import {useJwt} from "../../context/JwtContext";

const ProblemInfoModal = ({problem, onClose}) => {
    const {telegram} = useTelegram();
    const {jwtToken} = useJwt();

    return (
        <div className='problem-review-modal'>
            <div className='problem-review-modal-content'>
                <h3>Решение задачи</h3>
                <LatexRenderer latexCode={problem.tutorial}/>
                {problem.solutionImageUrl
                    ? <div style={{display: "flex", justifyContent: "center"}}>

                    </div>
                    : <p>Для этой задачи нет решения картинкой.</p>
                }
                <div className='problem-modal-buttons'>
                    <button
                        className={'downloadSolutionButton'}
                        onClick={() =>
                            telegram.openLink(`${constants.BACKEND_URL}/api/problemFiles/${problem.id}?jwt=${jwtToken}&fileType=SOLUTION`)}>
                        Скачать решение <FaFileDownload/>
                    </button>
                    <button className='problem-modal-close-button' onClick={onClose}>
                        Закрыть
                    </button>
                </div>
            </div>
        </div>
    )
};


export default ProblemInfoModal;
