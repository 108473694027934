import React, {useEffect, useRef, useState} from 'react';
import {HtmlGenerator, parse} from 'latex.js';

const LatexRenderer = ({latexCode}) => {
    const [error, setError] = useState('');
    const outputRef = useRef(null);
    const generator = new HtmlGenerator({hyphenate: false});

    useEffect(() => {
        const renderLatex = (input) => {
            generator.reset();
            try {
                const rendered = parse(input, {generator}).domFragment();
                setError('');
                if (outputRef.current) {
                    outputRef.current.innerHTML = '';
                    outputRef.current.appendChild(rendered);
                }
            } catch (e) {
                setError(e.message);
            }
        };

        renderLatex(latexCode);
    }, [latexCode]);

    return (
        <div className='modal-latex-viewer'>
            {error && <div id="error" style={{color: 'red'}}>{error}</div>}
            <div id="output" ref={outputRef}></div>
        </div>
    );
};

export default LatexRenderer;
