import React, {useEffect, useState} from 'react';
import './ReviewPage.css';
import {Link, useNavigate, useParams} from "react-router-dom";
import * as client from "../../../utils/client";
import {useTelegram} from "../../../hooks/useTelegram";
import {isPrivilegeUser} from "../../../utils/userUtil";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {useJwt} from "../../../context/JwtContext";
import LatexRenderer from "../../LatexRenderer/LatexRenderer";

const ReviewPage = () => {
    const {jwtToken, user} = useJwt();
    const {telegram} = useTelegram();
    const {reviewId} = useParams();
    const navigate = useNavigate();
    const [review, setReview] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(true);
    const [editedReview, setEditedReview] = useState({
        tag: '',
        text: '',
        beauty: '',
        complexity: '',
        wellKnown: false,
        sourceUrl: ''
    });
    const [errors, setErrors] = useState({});
    const [generalError, setGeneralError] = useState('');

    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (window.history.length > 1) {
            telegram.BackButton.show();
            telegram.BackButton.onClick(goBack);
        } else {
            telegram.BackButton.hide();
        }
        return () => {
            telegram.BackButton.offClick(goBack);
        };
    }, [telegram, navigate]);

    useEffect(() => {
        client.getReviewById(reviewId)
            .then((reviewJson) => {
                setReview(reviewJson);
                setEditedReview({
                    tag: reviewJson.tag || '',
                    text: reviewJson.text || '',
                    beauty: reviewJson.beauty || '',
                    complexity: reviewJson.complexity || '',
                    wellKnown: reviewJson.wellKnown || false,
                    sourceUrl: reviewJson.sourceUrl || ''
                });
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setGeneralError('Не удалось загрузить отзыв. Пожалуйста, попробуйте позже.');
                setLoading(false);
            });
    }, [reviewId]);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setEditedReview({
            tag: review.tag,
            text: review.text,
            beauty: review.beauty,
            complexity: review.complexity,
            wellKnown: review.wellKnown,
            sourceUrl: review.sourceUrl
        });
        setErrors({});
        setGeneralError('');
    };

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setEditedReview(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const validate = () => {
        const newErrors = {};

        if (!editedReview.tag.trim()) {
            newErrors.tag = 'Тэг ревью обязателен.';
        } else if (editedReview.tag.length > 50) {
            newErrors.tag = 'Тэг ревью не должен превышать 50 символов.';
        }

        if (!editedReview.text.trim()) {
            newErrors.text = 'Комментарий обязателен.';
        } else if (editedReview.text.length > 500) {
            newErrors.text = 'Комментарий не должен превышать 250 символов.';
        }

        const beautyValue = parseInt(editedReview.beauty, 10);
        if (isNaN(beautyValue)) {
            newErrors.beauty = 'Красота должна быть числом.';
        } else if (beautyValue < 1 || beautyValue > 6) {
            newErrors.beauty = 'Красота должна быть между 1 и 6.';
        }

        const complexityValue = parseInt(editedReview.complexity, 10);
        if (isNaN(complexityValue)) {
            newErrors.complexity = 'Сложность должна быть числом.';
        } else if (complexityValue < 1 || complexityValue > 6) {
            newErrors.complexity = 'Сложность должна быть между 1 и 6.';
        }

        if (editedReview.wellKnown && !editedReview.sourceUrl.trim()) {
            newErrors.sourceUrl = 'Источник обязателен для баянных ревью.';
        }

        if (!editedReview.wellKnown && editedReview.sourceUrl.trim()) {
            newErrors.sourceUrl = 'Источник должен быть пустым, если задача небаянистая.';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSaveClick = () => {
        if (validate()) {
            const updatedReview = {
                ...review,
                tag: editedReview.tag.trim(),
                text: editedReview.text.trim(),
                beauty: parseInt(editedReview.beauty, 10),
                complexity: parseInt(editedReview.complexity, 10),
                wellKnown: editedReview.wellKnown,
                sourceUrl: editedReview.wellKnown ? editedReview.sourceUrl.trim() : '',
                tgId: user.tgId
            };

            client.editReview(reviewId, updatedReview)
                .then((updatedReviewJson) => {
                    setReview(updatedReviewJson);
                    setIsEditing(false);
                    setErrors({});
                    setGeneralError('');
                })
                .catch((err) => {
                    console.error(err);
                    setGeneralError(err.response.data[0].defaultMessage);
                });
        }
    };

    if (!user || !isPrivilegeUser(user)) {
        return <div>Empty.</div>;
    }

    if (loading) {
        return <LoadingSpinner/>;
    }

    return (
        <div>
            <div className='problem-page-header'>
                <h1>Review</h1>
            </div>
            <Link to={`/problem/${review.problemId}`} className='review-link'>
                Задача: {review.problemName}
            </Link>
            <div className='review-details'>
                {isEditing && generalError && (
                    <div className="general-error-message">{generalError}</div>
                )}
                {isEditing ? (
                    <>
                        <label>
                            <span>Тэг ревью:</span>
                            <input
                                type="text"
                                name="tag"
                                className="input-field"
                                value={editedReview.tag}
                                onChange={handleChange}
                            />
                            {errors.tag && <div className="error-message">{errors.tag}</div>}
                        </label>
                        <label>
                            <span>Комментарий:</span>
                            <textarea
                                name="text"
                                className="input-field"
                                value={editedReview.text}
                                onChange={handleChange}
                                rows="4"
                            />
                            {errors.text && <div className="error-message">{errors.text}</div>}
                            <LatexRenderer latexCode={editedReview.text}/>
                        </label>
                        <label>
                            <span>Красота:</span>
                            <select
                                name="beauty"
                                className="input-field"
                                value={editedReview.beauty}
                                onChange={handleChange}
                            >
                                {[1, 2, 3, 4, 5, 6].map((value) => (
                                    <option key={value} value={value}>
                                        {value}
                                    </option>
                                ))}
                            </select>
                            {errors.beauty && <div className="error-message">{errors.beauty}</div>}
                        </label>
                        <label>
                            <span>Сложность:</span>
                            <select
                                name="complexity"
                                className="input-field"
                                value={editedReview.complexity}
                                onChange={handleChange}
                            >
                                {[1, 2, 3, 4, 5, 6].map((value) => (
                                    <option key={value} value={value}>
                                        {value}
                                    </option>
                                ))}
                            </select>
                            {errors.complexity && <div className="error-message">{errors.complexity}</div>}
                        </label>
                        <div className="checkbox-field">
                            <input
                                type="checkbox"
                                name="wellKnown"
                                checked={editedReview.wellKnown}
                                onChange={handleChange}
                            />
                            <label>Баян</label>
                        </div>
                        {editedReview.wellKnown && (
                            <label>
                                <span>Источник:</span>
                                <input
                                    type="text"
                                    name="sourceUrl"
                                    className="input-field"
                                    value={editedReview.sourceUrl}
                                    onChange={handleChange}
                                />
                                {errors.sourceUrl && <div className="error-message">{errors.sourceUrl}</div>}
                            </label>
                        )}
                    </>
                ) : (
                    <>
                        <p><span>Тэг ревью:</span> {review.tag}</p>
                        <p><span>Комментарий:</span> <LatexRenderer latexCode={review.text}/></p>
                        <p><span>Красота:</span> {review.beauty}</p>
                        <p><span>Сложность:</span> {review.complexity}</p>
                        <p>
                            <span>Баян:</span> {review.wellKnown ? "Да, " + review.sourceUrl : "Нет"}
                        </p>
                    </>
                )}
            </div>
            {user.id === review.userId && (
                <div className='review-buttons'>
                    {isEditing ? (
                        <>
                            <button className='save-button' onClick={handleSaveClick}>Сохранить</button>
                            <button className='cancel-button' onClick={handleCancelClick}>Отмена</button>
                        </>
                    ) : (
                        <button className='edit-button' onClick={handleEditClick}>Редактировать</button>
                    )}
                </div>
            )}
        </div>
    );
};

export default ReviewPage;
