import React from 'react';
import './HistoryReview.css';
import {Link} from "react-router-dom";

const HistoryReview = ({userReviewList}) => {
    return (
        <div className='history-review-page'>
            {userReviewList && userReviewList.filter(review => !review.deferred).length > 0 ? (
                <ul>
                    {userReviewList
                        .filter(review => !review.deferred)
                        .map((review, idx) => (
                            <Link to={`/review/${review.id}`} key={review.id}>
                                <li>
                                    #{idx + 1}: {review.tag}
                                </li>
                            </Link>
                        ))}
                </ul>
            ) : (
                <span style={{
                    textAlign: "center",
                    fontSize: '18px',
                    color: 'black',
                    margin: '20px 0',
                    padding: '15px',
                }}>Ревью еще нет.</span>
            )}
        </div>
    );
};


export default HistoryReview;
