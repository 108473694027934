import React, {useEffect, useState} from 'react';
import './ProblemPage.css';
import {useNavigate, useParams} from 'react-router-dom';
import * as client from '../../../utils/client';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import {useTelegram} from "../../../hooks/useTelegram";
import {useJwt} from "../../../context/JwtContext";
import {ProblemTheme, ProblemType} from "../../../utils/problemUtil";
import LatexRenderer from '../../LatexRenderer/LatexRenderer';
import * as constants from "../../../constants/constants";
import {isAdmin} from "../../../utils/userUtil";
import ProblemReviewsList from "../../ProblemReviewsList/ProblemReviewsList";

const ProblemPage = () => {
    const {jwtToken, user} = useJwt();
    const {problemId} = useParams();
    const {telegram} = useTelegram();
    const navigate = useNavigate();
    const [problem, setProblem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showReviews, setShowReviews] = useState(false);

    const goBack = () => {
        navigate(-1);
    };

    // const deleteProblem = () => {
    //     client.deleteProblem(problemId)
    //         .then(() => {
    //             alert("Задача успешно отозвана.");
    //             navigate('/');
    //         })
    //         .catch(error => {
    //             console.error("Error deleting problem:", error);
    //             alert("Ошибка при отзыве задачи.");
    //         });
    // };

    useEffect(() => {
        if (window.history.length > 1) {
            telegram.BackButton.show();
            telegram.BackButton.onClick(goBack);
        } else {
            telegram.BackButton.hide();
        }
        return () => {
            telegram.BackButton.offClick(goBack);
        };
    }, [telegram, navigate]);

    useEffect(() => {
        client.getProblemById(problemId)
            .then(problemJson => {
                setProblem(problemJson);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching problem:", error);
                setLoading(true);
            });
    }, [problemId]);

    if (loading) {
        return <LoadingSpinner/>;
    }

    return (
        <div style={{marginBottom: "3rem"}}>
            <div className={`problem-details ${showReviews ? 'blur' : ''}`}>
                <p className='problem-page-header'>
                    {problem.name}
                </p>
                {problem.coAuthors && problem.coAuthors.length > 0 && (
                    <div className='problem-coauthors'>
                        <p>Соавторы:</p>
                        {problem.coAuthors.map((coAuthor, index) => (
                            <p key={index} className='coauthor-name'>{coAuthor}</p>
                        ))}
                    </div>
                )}
                <LatexRenderer latexCode={problem.statement}/>
                {problem.illustrationImageUrl &&
                    <div className='problem-media'>
                        <img
                            src={`${constants.BACKEND_URL}/api/problemFiles/${problem.id}?jwt=${jwtToken}`}
                            alt="Problem Illustration"
                            className='problem-image'
                        />
                    </div>}
                {/*{user.id === problem.author.id && (*/}
                {/*    <button className='withdraw-button' onClick={deleteProblem}>*/}
                {/*        Отозвать задачу*/}
                {/*    </button>*/}
                {/*)}*/}
            </div>
            {isAdmin(user) &&
                <ProblemReviewsList toggleReviews={() => setShowReviews(prev => !prev)} problem={problem}
                                    showReviews={showReviews}/>
            }
        </div>
    );
};

export default ProblemPage;
