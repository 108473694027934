import React, {useEffect, useState} from 'react';
import './RandomProblem.css';
import {ProblemTheme, ProblemType} from "../../utils/problemUtil";
import * as client from "../../utils/client";
import LatexRenderer from "../LatexRenderer/LatexRenderer";
import {useJwt} from "../../context/JwtContext";
import sentToReview from "../../assets/sentToReview.svg";
import AddReviewForm from "../form/AddReviewForm/AddReviewForm";
import * as constants from "../../constants/constants";
import ReviewersList from "../ReviewersList/ReviewersList";

const RandomProblem = ({
                           reviewers,
                           deferredProblems,
                           setDeferredProblems,
                           problemForReview,
                           userReviewList,
                           setUserReviewList,
                           backToDeferredPool
                       }) => {
    const {jwtToken, user} = useJwt();
    const [problem, setProblem] = useState(null);
    const [showReviewers, setShowReviewers] = useState(false);

    const [selectedTheme, setSelectedTheme] = useState('ANY');
    const [selectedType, setSelectedType] = useState('ANY');

    useEffect(() => {
        if (problemForReview && !problem) {
            setProblem(problemForReview);
        }
    }, [problemForReview]);

    const deferProblem = () => {
        client.deferProblem(problem.id)
            .then(() => {
                setDeferredProblems(prevDeferredProblems => {
                    const existingIndex = prevDeferredProblems.findIndex(p => p.id === problem.id);
                    if (existingIndex >= 0) {

                        return prevDeferredProblems;
                    } else {
                        return [...prevDeferredProblems, problem];
                    }
                });

                if (!problemForReview) {
                    getRandomProblem();
                } else {
                    setProblem(null);
                    backToDeferredPool();
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    const onClose = () => {
        setSelectedType('ANY');
        setSelectedTheme('ANY');
        setProblem(null);

        if (problemForReview) {
            backToDeferredPool();
        }
    }

    const getRandomProblem = () => {
        const theme = selectedTheme !== 'ANY' ? selectedTheme : null;
        const type = selectedType !== 'ANY' ? selectedType : null;

        client.getRandomProblem(theme, type)
            .then(randomProblemJson => {
                if (!randomProblemJson) {
                    alert('Задачи кончились!');
                    setProblem(null);
                } else {
                    setProblem(randomProblemJson);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleAddReview = (reviewData, setGeneralError) => {
        const reviewCredentials = {
            tgId: user.tgId,
            problemId: problem.id,
            text: reviewData.text,
            beauty: reviewData.beauty,
            complexity: reviewData.complexity,
            wellKnown: reviewData.wellKnown,
            sourceUrl: reviewData.sourceUrl,
            tag: reviewData.tag
        };

        client.addReview(reviewCredentials)
            .then((review) => {
                setDeferredProblems((prevDeferredProblems) => {
                    const updatedDeferredProblems = prevDeferredProblems.filter(p => p.id !== problem.id);

                    if (updatedDeferredProblems.length > 0) {
                        const nextProblem = updatedDeferredProblems[0];
                        setProblem(nextProblem);
                    } else {
                        getRandomProblem();
                    }

                    return updatedDeferredProblems;
                });

                setUserReviewList((prevUserReviewList) => [...prevUserReviewList, review]);
            })
            .catch(error => {
                setGeneralError(error.response?.data || "An unexpected error occurred.");
            });
    };

    return (
        problem
            ?
            <div className={`modal-container ${showReviewers ? 'over-hidden' : ''}`}>
                <div className='problem-review-modal-header'>
                    <h1>{problem.name}</h1>
                    <img src={sentToReview} alt={'sent-to-review'} onClick={() => setShowReviewers(!showReviewers)}/>
                </div>
                <div className='problem-review-modal-container'>
                    <p>Условие</p>
                    <LatexRenderer latexCode={problem.statement}/>
                </div>
                <div className='problem-review-modal-container'>
                    {problem.illustrationImageUrl &&
                        <div className='problem-media'>
                            <img
                                src={`${constants.BACKEND_URL}/api/problemFiles/${problem.id}?jwt=${jwtToken}`}
                                alt="Problem Illustration"
                                className='problem-image'
                            />
                        </div>
                    }
                </div>
                <AddReviewForm key={problem.id} onSubmit={handleAddReview} onClose={onClose}
                               deferProblem={deferProblem}/>

                {showReviewers && <ReviewersList problemId={problem.id} reviewers={reviewers} onClose={() => setShowReviewers(false)}/>}
            </div>
            :
            <div>
                <div className='filters-container'>
                    <div className='filter-group'>
                        <label style={{justifyContent: "center"}}>Тема</label>
                        <div className="checkbox-group">
                            <label>
                                <input
                                    type="checkbox"
                                    value="ANY"
                                    checked={selectedTheme === 'ANY'}
                                    onChange={(e) => setSelectedTheme(e.target.checked ? 'ANY' : '')}
                                />
                                Любой
                            </label>
                            {Object.entries(ProblemTheme).map(([key, value]) => (
                                <label key={key}>
                                    <input
                                        type="checkbox"
                                        value={key}
                                        checked={selectedTheme === key}
                                        onChange={(e) => setSelectedTheme(e.target.checked ? key : '')}
                                    />
                                    {value}
                                </label>
                            ))}
                        </div>
                    </div>

                    <div className='filter-group'>
                        <label style={{justifyContent: "center"}}>Тип</label>
                        <div className="checkbox-group">
                            <label>
                                <input
                                    type="checkbox"
                                    value="ANY"
                                    checked={selectedType === 'ANY'}
                                    onChange={(e) => setSelectedType(e.target.checked ? 'ANY' : '')}
                                />
                                Любой
                            </label>
                            {Object.entries(ProblemType).map(([key, value]) => (
                                <label key={key}>
                                    <input
                                        type="checkbox"
                                        value={key}
                                        checked={selectedType === key}
                                        onChange={(e) => setSelectedType(e.target.checked ? key : '')}
                                    />
                                    {value}
                                </label>
                            ))}
                        </div>
                    </div>
                </div>

                <button className='add-problem-submit-button' onClick={getRandomProblem}>
                    Найти
                </button>
            </div>

    );
};

export default RandomProblem;
