import React, {useState} from 'react';
import './RegisterPage.css';
import {useTelegram} from "../../../hooks/useTelegram";
import * as client from "../../../utils/client";
import {UserStatus} from "../../../utils/userUtil";

const RegisterPage = () => {
    const {telegramUser, cloudStorage} = useTelegram();
    const [error, setError] = useState(null);
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [userCredentials, setUserCredentials] = useState({
        tgId: telegramUser?.id || '',
        username: telegramUser?.username || '',
        firstName: '',
        lastName: '',
        email: '',
        status: 'STUDENT',
        placeOfWorkOrStudy: '',
        classOrCourse: '',
        countryOfResidence: '',
        city: '',
    });

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setUserCredentials((prev) => ({...prev, [name]: value}));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!isTermsAccepted) {
            setError('You must accept the terms and conditions.');
            return;
        }

        client.registerUser(userCredentials)
            .then((jwtToken) => {
                cloudStorage.setItem("jwt", jwtToken);
                window.location.reload();
            })
            .catch(error => {
                setError(error.response.data);
            });
    };

    return (
        <div className="register-page">
            <h2 className='register-page-header'>Регистрация</h2>
            <form onSubmit={handleSubmit} style={{marginBottom: "5rem"}}>
                <div className="form-group">
                    <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder={'Имя'}
                        value={userCredentials.firstName}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder='Фамилия'
                        value={userCredentials.lastName}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="email"
                        id="email"
                        placeholder='Email'
                        name="email"
                        value={userCredentials.email}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <select
                        id="status"
                        name="status"
                        value={userCredentials.status}
                        onChange={(e) => {
                            setUserCredentials({...userCredentials, status: e.target.value})
                        }}
                        required
                    >
                        {Object.entries(UserStatus).map(([key, value]) => (
                            <option key={key} value={key}>
                                {value}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <input
                        placeholder='Место учебы/работы'
                        type="text"
                        id="placeOfWorkOrStudy"
                        name="placeOfWorkOrStudy"
                        value={userCredentials.placeOfWorkOrStudy}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <input
                        placeholder='Класс или курс(для студентов)'
                        type="text"
                        id="classOrCourse"
                        name="classOrCourse"
                        value={userCredentials.classOrCourse}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <input
                        placeholder='Страна проживания'
                        type="text"
                        id="countryOfResidence"
                        name="countryOfResidence"
                        value={userCredentials.countryOfResidence}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <input
                        placeholder='Город проживания'
                        type="text"
                        id="city"
                        name="city"
                        value={userCredentials.city}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="accept-terms-container" style={{display: "flex", flexDirection: "row"}}>
                    <input
                        style={{width: "auto"}}
                        type="checkbox"
                        id="terms"
                        checked={isTermsAccepted}
                        onChange={(e) => setIsTermsAccepted(e.target.checked)}
                    />
                    <label htmlFor="terms" style={{fontSize: "1.1rem"}}>
                        Я принимаю <a href="/agreement.pdf" target="_blank" rel="noopener noreferrer">
                        условия и соглашения.</a>
                    </label>
                </div>
                {error && <p className="error">{error}</p>}
                {isTermsAccepted && <button style={{width: "100%"}} type="submit"
                                            className="add-problem-submit-button"
                                            disabled={!isTermsAccepted}>Зарегистрироваться</button>}
            </form>
        </div>
    );
};

export default RegisterPage;
