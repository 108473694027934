import React from 'react';
import './DeferredPool.css';

const DeferredPool = ({deferredProblems, setProblemForReview}) => {
    return (
        <div className='deferred-pool-page'>
            <ul>
                {deferredProblems && deferredProblems.length > 0
                    ? deferredProblems.map(problem => (
                        <li key={problem.id}
                            onClick={() => {
                                setProblemForReview(problem);
                            }}
                        >
                            {problem.name}
                        </li>
                    ))
                    : <p>У вас еще нет отложенных задач.</p>
                }
            </ul>
        </div>
    );
};

export default DeferredPool;
