import React, {useEffect, useState} from 'react';
import './ReviewsPage.css';
import HistoryReview from "../../HistoryReview/HistoryReview";
import RandomProblem from "../../RandomProblem/RandomProblem";
import DeferredPool from "../../DeferredPool/DeferredPool";

const ReviewsPage = ({reviewers, userReviewList, setUserReviewList, deferredProblems, setDeferredProblems}) => {
    const [content, setContent] = useState(null);
    const [activeButton, setActiveButton] = useState("historyReview");

    useEffect(() => {
        if (!content) {
            setContent(historyReviewContent);
        }
    }, []);

    const historyReviewContent = <HistoryReview userReviewList={userReviewList}/>;

    const randomProblemContent = <RandomProblem
        reviewers={reviewers}
        deferredProblems={deferredProblems}
        userReviewList={userReviewList} setUserReviewList={setUserReviewList}
        setDeferredProblems={setDeferredProblems} problemForReview={null}/>;

    const deferredPoolContent = <DeferredPool deferredProblems={deferredProblems}
                                              setProblemForReview={(p) => {
                                                  setActiveButton("randomProblem");
                                                  setContent(<RandomProblem
                                                      reviewers={reviewers}
                                                      backToDeferredPool={() => handleMenuClick(deferredPoolContent, "deferredPool")}
                                                      deferredProblems={deferredProblems}
                                                      userReviewList={userReviewList}
                                                      setUserReviewList={setUserReviewList}
                                                      setDeferredProblems={setDeferredProblems}
                                                      problemForReview={p}
                                                  />)
                                              }}/>;

    const handleMenuClick = (content, buttonId) => {
        setContent(content);
        setActiveButton(buttonId);
    };

    return (
        <div className='reviews-page-container'>
            <div className='reviews-page-menu'>
                <button
                    className={`reviews-page-menu-button ${activeButton === "historyReview" ? "active" : ""}`}
                    onClick={() => handleMenuClick(historyReviewContent, "historyReview")}
                >
                    История ревью
                </button>
                <button
                    className={`reviews-page-menu-button ${activeButton === "randomProblem" ? "active" : ""}`}
                    onClick={() => handleMenuClick(randomProblemContent, "randomProblem")}
                >
                    Случайная задача
                </button>
                <button
                    className={`reviews-page-menu-button ${activeButton === "deferredPool" ? "active" : ""}`}
                    onClick={() => handleMenuClick(deferredPoolContent, "deferredPool")}
                >
                    Отложенные задачи
                </button>
            </div>
            <div className="reviews-page-content">
                {content}
            </div>
        </div>
    );
};

export default ReviewsPage;
