import React from 'react';
import './LoadingSpinner.css';

const LoadingSpinner = () => {
    return (
        <div className="spinner-wrapper">
            <div className="spinner"></div>
            <p className='loading-footer'>Дабромат</p>
        </div>
    );
};

export default LoadingSpinner;
