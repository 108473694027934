import React, {useRef, useEffect} from 'react';
import './ProblemReviewsList.css';
import {AiOutlineLike} from "react-icons/ai";
import {FiActivity} from "react-icons/fi";

import LatexRenderer from "../LatexRenderer/LatexRenderer";

const ProblemReviewsList = ({problem, showReviews, toggleReviews}) => {
    const reviewsRef = useRef(null);

    const handleOutsideClick = (e) => {
        if (reviewsRef.current && !reviewsRef.current.contains(e.target)) {
            toggleReviews();
        }
    };

    useEffect(() => {
        if (showReviews) {
            document.addEventListener('click', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [showReviews]);

    return (
        <div className="problem-reviews">
            <button className="show-problem-reviews-button" onClick={(e) => {
                e.stopPropagation();
                toggleReviews();
            }}>
                Показать все ревью
            </button>

            {showReviews && problem.reviews.length > 0
                ? <div className="reviews-list" ref={reviewsRef}>
                    {
                        problem.reviews
                            .filter(review => !review.deferred)
                            .map((review) => (
                                <div key={review.id} className="review">
                                    <p className="review-latex"><LatexRenderer latexCode={review.text}/></p>
                                    <div className="review-header">
                                        <div className="icons">
                                            <p><AiOutlineLike/> {review.beauty}</p>
                                            <p><FiActivity/> {review.complexity}</p>
                                        </div>
                                    </div>
                                    <p>Баян: {review.wellKnown ? "Да, " + review.sourceUrl : "Нет"}</p>
                                    <div className="review-footer">
                                        <p>Автор: {review.reviewAuthorCredentials}</p>
                                    </div>
                                </div>

                            ))
                    }
                </div>
                : <p>Пока что здесь пусто...</p>
            }
        </div>
    );
};

export default ProblemReviewsList;
