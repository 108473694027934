import React from 'react';
import './ProblemList.css';
import {Link} from "react-router-dom";
import {ProblemTheme, ProblemType} from "../../utils/problemUtil";

const ProblemList = ({problems}) => {
    return (
        <table className='problems-list'>
            <thead>
            <tr>
                <th>Название</th>
                <th>Тип</th>
                <th>Тема</th>
            </tr>
            </thead>
            <tbody>
            {problems.map((problem) => (
                <tr key={problem.id} className='problem-row'>
                    <td>
                        <Link to={`/problem/${problem.id}`} className='problem-name'>
                            {problem.name}
                        </Link>
                    </td>
                    <td>{ProblemTheme[problem.theme] || problem.theme}</td>
                    <td>{ProblemType[problem.type] || problem.type}</td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};


export default ProblemList;
